import axios from "axios";
import { PredictionResult } from "./DropZone";
import { firebase } from "./firebase";

const endpoint = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://0.0.0.0:5000/car";
  } else {
    return "https://mudcar.bitapp.hk/car";
  }
};
axios.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    config.headers["Authorization"] = "Bearer " + token;
    config.timeout = 20000;
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

export const getPredictions = (formData: FormData, isUseYolo: boolean) =>
  (process.env.NODE_ENV === "development"
    ? Promise.resolve({
        file_name: "test",
        predictions: [
          { confidence: 0.5123, label: "Model 1" },
          { confidence: 0.2221, label: "Model 2" },
          { confidence: 0.2089, label: "Model 3" },
          { confidence: 0.0567, label: "Model 4" },
          { confidence: 0.00002, label: "Model 5" },
        ],
      })
    : axios
        .post(
          endpoint() + "/prediction" + (!isUseYolo ? "?yolo=false" : ""),
          formData
        )
        .then(
          (res) => {
            let data = res.data;
            return Promise.resolve(data);
          },
          () => {
            return Promise.reject();
          }
        )) as Promise<PredictionResult>;

export const submitFeedback = (formData: FormData) =>
  (process.env.NODE_ENV === "development"
    ? Promise.resolve({})
    : axios.post(endpoint() + "/prediction/feedback", formData).then(
        () => {
          return Promise.resolve();
        },
        () => {
          return Promise.reject();
        }
      )) as Promise<void>;

export const getModelList = () =>
  (process.env.NODE_ENV === "development"
    ? Promise.resolve([
        "Alfa Giulietta",
        "Alfa Mito",
        "Alfa Romeo Stelvio",
        "Aston Martin DB11",
        "Aston Martin DB7",
        "Aston Martin DB9",
        "Aston Martin DBS Superleggera",
        "Aston Martin Rapide",
        "Aston Martin Vantage 2018",
        "Aston-Martin V8-Vantage",
        "Aston-Martin Virage 2011",
        "Audi 100",
        "Audi A1 2018",
        "Audi A1 8X",
        "Audi A3 8L",
        "Audi A3 8P",
        "Audi A3 8V",
        "Audi A4 B5",
        "Audi A4 B7",
        "Audi A4 B8",
        "Audi A4 B9",
        "Audi A5 B8",
        "Audi A5 B9",
        "Audi A6 C5",
        "Audi A6 C6",
        "Audi A6 C7",
        "Audi A7",
        "Audi A8",
        "Audi Q2",
        "Audi Q3",
        "Audi Q5",
        "Audi Q7",
        "Audi Q8",
        "Audi R8 1Gen",
        "Audi R8 2Gen",
        "Audi TT MK1",
        "Audi TT MK2",
        "Audi TT MK3",
        "BMW 1-Series E82",
        "BMW 1-Series F20",
        "BMW 2-Series Active Tourer",
        "BMW 2-Series F22",
        "BMW 3-Series E36",
        "BMW 3-Series E46",
        "BMW 3-Series E90",
        "BMW 3-Series F30",
        "BMW 3-Series G20",
        "BMW 4-Series",
        "BMW 5-Series E60",
        "BMW 5-Series F07GT",
        "BMW 5-Series F10",
        "BMW 5-Series G30",
        "BMW 6-Series E63",
        "BMW 6-Series F06",
        "BMW 6-Series G32",
        "BMW 7-Series E65",
        "BMW 7-Series F01",
        "BMW 7-Series G11",
        "BMW 8-Series E31",
        "BMW 8-Series G15",
        "BMW X1 E84",
        "BMW X1 F48",
        "BMW X2",
        "BMW X3 F25",
        "BMW X3 G01",
        "BMW X4",
        "BMW X5 E53",
        "BMW X5 E70",
        "BMW X5 F15",
        "BMW X5 G05",
        "BMW X6 E71",
        "BMW X6 F16",
        "BMW Z3",
        "BMW Z4 E85",
        "BMW Z4 E89",
        "BMW Z4 G29",
        "BMW i3",
        "BMW i8",
        "Bentley Arnage Sedan 2009",
        "Bentley Bentayga",
        "Bentley Continental Flying Spur Sedan 2007",
        "Bentley Continental GT",
        "Bentley Continental Supersports Conv. Convertible 2012",
        "Bentley Mulsanne Sedan 2011",
        "Bugatti Veyron 16.4 Convertible 2009",
        "Bugatti Veyron 16.4 Coupe 2009",
        "Buick Enclave SUV 2012",
        "Buick Rainier SUV 2007",
        "Buick Regal Gs 2012",
        "Buick Verano Sedan 2012",
        "Cadillac CTS-V Sedan 2012",
        "Cadillac Escalade Ext Crew Cab 2007",
        "Cadillac SRX SUV 2012",
        "Chevrolet Avalanche Crew Cab 2012",
        "Chevrolet Camaro Convertible 2012",
        "Chevrolet Cobalt Ss 2010",
        "Chevrolet Corvette Convertible 2012",
        "Chevrolet Corvette Ron Fellows Edition Z06 2007",
        "Chevrolet Corvette Zr1 2012",
        "Chevrolet Express Cargo Van 2007",
        "Chevrolet Express Van 2007",
        "Chevrolet Hhr Ss 2010",
        "Chevrolet Impala Sedan 2007",
        "Chevrolet Malibu Hybrid Sedan 2010",
        "Chevrolet Malibu Sedan 2007",
        "Chevrolet Monte Carlo Coupe 2007",
        "Chevrolet Silverado 1500 Classic Extended Cab 2007",
        "Chevrolet Silverado 1500 Extended Cab 2012",
        "Chevrolet Silverado 1500 Hybrid Crew Cab 2012",
        "Chevrolet Silverado 1500 Regular Cab 2012",
        "Chevrolet Silverado 2500Hd Regular Cab 2012",
        "Chevrolet Sonic Sedan 2012",
        "Chevrolet Tahoe Hybrid SUV 2012",
        "Chevrolet Trailblazer Ss 2009",
        "Chevrolet Traverse SUV 2012",
        "Chrysler 300 Srt-8 2010",
        "Chrysler Aspen SUV 2009",
        "Chrysler Crossfire Convertible 2008",
        "Chrysler Pt Cruiser Convertible 2008",
        "Chrysler Sebring Convertible 2010",
        "Chrysler Town And Country Minivan 2012",
        "Citroen Cactus",
        "Citroen DS3",
        "Citroen DS4",
        "Daewoo Nubira Wagon 2002",
        "Dodge Caliber Wagon 2007",
        "Dodge Caliber Wagon 2012",
        "Dodge Caravan Minivan 1997",
        "Dodge Challenger Srt8 2011",
      ])
    : axios.get(endpoint() + "/models").then(
        (res) => {
          let data = res.data;
          console.log(data);
          return Promise.resolve(data);
        },
        () => {
          return Promise.reject();
        }
      )) as Promise<any>;

export const getToken = () => {
  console.log("getoken");
  return new Promise(async (resolve, reject) => {
    try {
      const currentUser = firebase.auth().currentUser;
      if (currentUser !== null) {
        const idToken = await currentUser.getIdToken(true);
        resolve(idToken);
      } else {
        firebaseSignInAnonymously();
      }
    } catch (error) {
      console.log("get token error");
      reject(error);
    }
  });
};

export const firebaseSignInAnonymously = async () => {
  try {
    await firebase.auth().signInAnonymously();
    console.log(firebase.auth().currentUser);
    await getToken();
  } catch (e) {
    switch (e.code) {
      case "auth/operation-not-allowed":
        console.log("Enable anonymous in your firebase console.");
        break;
      default:
        console.error(e);
        break;
    }
  }
};
